import { createSlice } from '@reduxjs/toolkit';
import { AnalysisState } from '../../@types/analysis';

const initialState: AnalysisState = {
  isLoading: false,
  modelList: {},
};

const slice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getModelListSuccess(state, action) {
      state.isLoading = false;
      state.modelList = action.payload;
    },

    getImportanceListSuccess(state, action) {
      state.isLoading = false;
      state.importanceList = action.payload;
    },

    getColumnListSuccess(state, action) {
      state.isLoading = false;
      state.columnList = action.payload;
    },

    resetPrediction(state) {
      state.predictionList = undefined
    },

    getPredictionListSuccess(state, action) {
      state.isLoading = false;
      state.predictionList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, finishLoading, hasError, resetError,
  getModelListSuccess, getImportanceListSuccess, getColumnListSuccess,
  resetPrediction, getPredictionListSuccess
} = slice.actions;

