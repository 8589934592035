// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
import Logo from '../../components/Logo';
import Image from 'src/components/Image';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const LogoStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 0,
  lineHeight: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: theme.spacing(10),
    justifyContent: 'left',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Вход" style={{
      background: "#F6F7FB url('/img/background.png') no-repeat",
      backgroundSize: "auto",
      backgroundPosition: "center right",
    }}>
      <LogoStyle>
        <img src="/logo/sibit_logo.webp" alt="logo" />
      </LogoStyle>
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
