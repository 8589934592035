// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------
const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  account: getIcon('account'),
  data: getIcon('data'),
  analytics: getIcon('analytics'),
  forecasts: getIcon('forecasts'),
  users: getIcon('users'),
};

const navConfig = [
  {
    subheader: '',
    items: [
      { title: 'Профиль', path: PATH_DASHBOARD.account, icon: ICONS.account },
      { title: 'Данные', path: PATH_DASHBOARD.data.root, icon: ICONS.data },
      { title: 'Аналитика', path: PATH_DASHBOARD.analytics, icon: ICONS.analytics },
      { title: 'Прогнозы', path: PATH_DASHBOARD.forecasts, icon: ICONS.forecasts },
      { title: 'Пользователи', path: PATH_DASHBOARD.users.root, icon: ICONS.users, }
    ],
  },
];

export default navConfig;
