import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '../../@types/data';

const initialState: DataState = {
  isLoading: false,
  dataList: {},
  dataListParams: {},
  dataItem: {},
  dataItemParams: {},
  dataItemStructure: {},
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getDataListSuccess(state, action) {
      state.isLoading = false;
      state.dataList = action.payload;
    },

    setDataListParams(state, action){
      state.dataListParams = action.payload;
    },

    getDataItemSuccess(state, action) {
      state.isLoading = false;
      state.dataItem = action.payload;
    },

    setDataItemParams(state, action){
      state.dataItemParams = action.payload;
    },

    setDataItemStructure(state, action){
      state.dataItemStructure = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, finishLoading, hasError, resetError,
  getDataListSuccess, setDataListParams, getDataItemSuccess, setDataItemParams, setDataItemStructure
} = slice.actions;

